@font-face {
    font-family: 'Nasalization';
    font-weight: 400;
    src: url('../static/fonts/Nasalization.otf');
}

*
{
    margin: 0;
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.webgl#complementary-webgl-experience
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    width: 300px;
    height: 450px;
    background-color: rgb(222, 222, 222);
    margin: 12.5vh 0 0 0;
}

#start-button
{
    display: flex;
    position: absolute;
    width: 120px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -60px;
    z-index: 10;
    background-color: white;
    font-family: 'Nasalization';
    font-size: 1.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.panolens-video
{
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.panolens-container
{
    position: absolute;
    z-index: 2;
}
.panolens-container div
{
    display: none;
}

/* INTERFACE */
#enter-meeting
{
    height: 20px;
    width: 105px;
    position: absolute;
    z-index: 10;
    top: 55px;
    left: 10px;
    background-color: #e5e5e5;
    padding: 6px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

#exit-button
{
    height: 20px;
    width: 40px;
    position: absolute;
    z-index: 10;
    top: 90px;
    left: 10px;
    background-color: #e5e5e5;
    padding: 6px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

#firts-person-aim
{
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px; /* Half the height */
    margin-left: -10px; /* Half the width */
    opacity: 60%;
}

#minimap-container
{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 20px;
    background-color: #e5e5e5;
    display: grid;
    border-radius: 10px;
    gap: 2px;
}

.minimapSection
{
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffffff20;
    border: 1.5px solid #bebec0;
    font-size: 0.6rem;
    color: #6f6f70;
    font-family: 'Nasalization';
    text-align: center;
}
.minimapSection p
{
    margin: auto;
}
.minimapSection__current
{
    background-color: #fe510120;
    border: 1.5px solid #fe5101;
}

#arrow
{
    position: absolute;
    height: 12px;
    width: 12px;
    margin: 8px;
    pointer-events: none;
}

.noSelect
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}